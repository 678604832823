:root {
  --background: rgb(220, 232, 232);
  --green: #70b96f;
  --green-tint: #70b96f;
  --dark-gray: #2d2d2d;
}

a,
a:active,
a:visited,
a:focus {
  color: var(--green);
}

.button,
button {
  appearance: none;
  background: var(--green-tint);
  border: solid 2px var(--green);
  border-radius: 7px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: white !important;
  cursor: pointer;
  display: inline-flex;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  padding: 0.5em 1em;
}

.button:hover,
button:hover {
  filter: brightness(110%);
  transition: all ease-in-out 0.25s;
}

* {
  box-sizing: border-box;
}

body {
  background: var(--background);
  color: var(--dark-gray);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.subpage {
  text-align: center;
}

.subpage header {
  background: var(--dark-gray);
}

.subpage .logo-container {
  justify-content: center;
}

.subpage h2 {
  font-size: 4.5em;
}

.login-complete h2 {
  margin: 0;
}

.privacy h2 {
  margin: 0.5em auto;
}

.login-complete h3 {
  font-size: 2.75em;
  margin: 0 0 1em;
}

.subpage h3 {
  font-size: 2em;
  margin: 1em auto;
  max-width: 800px;
  text-align: justify;
}

.subpage p {
  font-size: 1.5em;
  max-width: 800px;
  margin: auto;
  line-height: 1.4;
  text-align: justify;
}

.login-complete button {
  margin: 3em;
}

.home header {
  background-image: url('./images/home_cat.jpg');
  background-size: cover;
  background-position: center;
  min-height: 50vh;
  max-height: 800px;
}

.home p,
.home h2,
.home ul {
  max-width: 1024px;
  margin: 20px auto;
}

.home section {
  margin-bottom: 5em;
}

header,
main,
footer {
  padding: 2em;
}

.logo-container {
  align-items: center;
  color: white;
  display: flex;
}

.logo-container .logo {
  margin-right: 10px;
}

.logo {
  height: 64px;
  width: 64px;
}

.logo.large {
  height: 128px;
  width: 128px;
}

.home header {
  align-items: flex-start;
  display: flex;
}

.home header .cta {
  flex: 1 1 auto;
  text-align: right;
}

.home header .cta {
  margin-top: 20px;
}

.home main .cta {
  display: none;
  margin-top: 4em;
  text-align: center;
}

.home main .cta .button {
  font-size: 1.5em;
}

@media (max-width: 600px) {
  .home header {
    flex-wrap: wrap;
  }

  .home header .cta {
    flex: 1 1 100%;
    text-align: center;
  }

  .home header .cta .button {
    display: none;
  }

  .home main .cta {
    display: block;
  }
}

.home footer {
  background: var(--dark-gray);
  font-weight: bold;
  color: white;
  text-align: center;
}

.home footer p {
  margin: 1em auto;
}

.home h2 {
  font-size: 2em;
}

.home p,
.home li {
  font-size: 1.5em;
  line-height: 1.5;
}

.home li {
  margin-bottom: 10px;
}

.home footer p {
  flex: 1 1 auto;
}

.home footer p {
  font-size: 1em;
}

.home footer a {
  font-size: 1em;
  color: white;
}

.not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.not-found h1 {
  margin-bottom: 0;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 2em auto;
}

form label {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 0.75em;
  margin-top: 1em;
  text-align: left !important;
}

form button {
  display: inline;
  align-self: center;
  margin-top: 3em;
}

input,
textarea,
select {
  background: white;
  border: 1px solid var(--dark-gray);
  border-radius: 6px;
  font-size: 1em;
  padding: 5px;
  resize: none;
  text-align: left !important;
}

textarea {
  min-height: 300px;
}

input {
  appearance: none;
}
